<template>
  <div class="game-recommend-page">
    <div class="content">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="page-title">
            <h1>游戏推荐</h1>
            <p>根据您的喜好筛选和发现适合您的游戏</p>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- 左侧筛选面板 -->
        <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="6">
          <el-card class="filter-card">
            <div slot="header" class="filter-header">
              <span>筛选条件</span>
              <el-button type="text" @click="resetFilters">重置条件</el-button>
            </div>
            
            <el-form label-position="top" :model="recommendGame">
              <el-form-item label="最早发行日期">
                <el-date-picker
                  v-model="recommendGame.releasedate"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%">
                </el-date-picker>
              </el-form-item>
              
              <el-form-item label="价格上限">
                <el-slider 
                  v-model="recommendGame.price" 
                  :min="0" 
                  :max="500" 
                  :step="10"
                  :format-tooltip="formatPrice">
                </el-slider>
              </el-form-item>

              <el-form-item label="游戏标签">
                <el-select
                  v-model="recommendGame.tags"
                  multiple
                  collapse-tags
                  placeholder="选择标签"
                  style="width: 100%">
                  <el-option
                    v-for="item in tagOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="评价">
                <el-select 
                  v-model="recommendGame.recentreviews" 
                  placeholder="请选择"
                  style="width: 100%">
                  <el-option
                    v-for="item in reviewOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="最高评价数量">
                <el-input-number 
                  v-model="recommendGame.numberofreviews" 
                  :min="0" 
                  controls-position="right"
                  style="width: 100%">
                </el-input-number>
              </el-form-item>
              
              <el-form-item label="最低好评率">
                <el-slider 
                  v-model="recommendGame.goodrating" 
                  :min="0" 
                  :max="100" 
                  :format-tooltip="formatRating">
                </el-slider>
              </el-form-item>
              
              <el-form-item label="游戏支持语言">
                <el-select
                  v-model="recommendGame.lang"
                  multiple
                  collapse-tags
                  placeholder="选择语言"
                  style="width: 100%">
                  <el-option
                    v-for="item in languageOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="getgameInfo" style="width: 100%;">
                  <i class="el-icon-search"></i> 查找推荐游戏
                </el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>

        <!-- 右侧游戏列表 -->
        <el-col :xs="24" :sm="24" :md="16" :lg="18" :xl="18">
          <el-card class="game-list-card">
            <div v-if="loading" class="loading-container">
              <el-skeleton :rows="6" animated />
              <el-skeleton :rows="6" animated style="margin-top: 20px" />
            </div>
            
            <div v-else-if="gameList.length === 0" class="empty-state">
              <i class="el-icon-goods empty-icon"></i>
              <p>没有找到匹配的游戏，请尝试调整筛选条件</p>
            </div>
            
            <div v-else class="game-list">
              <el-card 
                v-for="game in gameList" 
                :key="game.id"
                class="game-card"
                shadow="hover">
                <div class="game-card-content">
                  <div class="game-image">
                    <img :src="game.imgsrc" :alt="game.name" @click="changeGogame(game)" />
                    <div class="game-price" :class="{'free': game.price === '免费开玩'}">
                      {{ game.price }}
                    </div>
                  </div>
                  
                  <div class="game-info">
                    <h3 class="game-title" @click="changeGogame(game)">{{ game.name }}</h3>
                    <div class="game-id">游戏ID: {{ game.gameid }}</div>
                    
                    <div class="game-tags">
                      <el-tag type="success" size="small">好评率: {{ game.goodrating }}%</el-tag>
                      <el-tag type="info" size="small">评价: {{ game.recentreviews }}</el-tag>
                      <el-tag type="warning" size="small">发行日期: {{ formatDate(game.releasedate) }}</el-tag>
                      <el-tag type="primary" size="small">开发商: {{ game.developers }}</el-tag>
                    </div>
                    
                    <div class="game-description" v-if="game.description">
                      {{ game.description }}
                    </div>
                    
                    <div class="game-actions">
                      <el-button type="primary" size="small" @click="changeGogame(game)">查看详情</el-button>
                      <el-button type="success" size="small" @click="addToWishlist(game)">
                        <i class="el-icon-star-off"></i> 加入愿望单
                      </el-button>
                    </div>
                  </div>
                </div>
              </el-card>
              
              <div class="pagination-container" v-if="gameList.length > 0">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="totalGames"
                  :page-size="pageSize"
                  :current-page="currentPage"
                  @current-change="handlePageChange">
                </el-pagination>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      currentPage: 1,
      pageSize: 10,
      totalGames: 0,
      reviewOptions: [
        { value: '特别好评', label: '特别好评' },
        { value: '好评', label: '好评' },
        { value: '多半好评', label: '多半好评' },
        { value: '褒贬不一', label: '褒贬不一' },
        { value: '好评如潮', label: '好评如潮' },
        { value: '多半差评', label: '多半差评' },
        { value: '特别差评', label: '特别差评' },
        { value: '差评如潮', label: '差评如潮' }
      ],
      tagOptions: [
        { value: '动作', label: '动作' },
        { value: '冒险', label: '冒险' },
        { value: '休闲', label: '休闲' },
        { value: '模拟', label: '模拟' },
        { value: '策略', label: '策略' },
        { value: '角色扮演', label: '角色扮演' },
        { value: '独立', label: '独立' },
        { value: '多人', label: '多人' },
        { value: '大型多人在线', label: '大型多人在线' }
      ],
      languageOptions: [
        { value: '简体中文', label: '简体中文' },
        { value: '繁体中文', label: '繁体中文' },
        { value: '英语', label: '英语' },
        { value: '日语', label: '日语' },
        { value: '韩语', label: '韩语' },
        { value: '法语', label: '法语' },
        { value: '德语', label: '德语' },
        { value: '西班牙语', label: '西班牙语' }
      ],
      recommendGame: {
        price: 200,
        tags: [],
        recentreviews: null,
        numberofreviews: 100,
        goodrating: 60,
        lang: ['简体中文'],
        releasedate: null
      },
      gameList: [
        {
          id: 1,
          gameid: '1245620',
          name: '艾尔登法环',
          price: '298',
          imgsrc: 'https://media.st.dl.eccdnx.com/steam/apps/1245620/header.jpg',
          goodrating: 92,
          recentreviews: '好评如潮',
          releasedate: '2022-02-25',
          developers: 'FromSoftware',
          description: '由FromSoftware带来的一款新的暗黑奇幻动作RPG。踏上旅程，探索充满未知的广阔世界，遭遇宏伟的城堡和恐怖的野兽。'
        },
        {
          id: 2,
          gameid: '1091500',
          name: '赛博朋克2077',
          price: '298',
          imgsrc: 'https://media.st.dl.eccdnx.com/steam/apps/1091500/header.jpg',
          goodrating: 84,
          recentreviews: '特别好评',
          releasedate: '2020-12-10',
          developers: 'CD PROJEKT RED',
          description: '赛博朋克2077是一款开放世界动作冒险RPG游戏，故事发生在暗黑未来的夜之城。'
        },
        {
          id: 3,
          gameid: '990080',
          name: '霍格沃茨之遗',
          price: '298',
          imgsrc: 'https://media.st.dl.eccdnx.com/steam/apps/990080/header.jpg',
          goodrating: 79,
          recentreviews: '多半好评',
          releasedate: '2023-02-10',
          developers: 'Avalanche Software',
          description: '《霍格沃茨之遗》是一款开放世界的动作角色扮演游戏，让玩家体验魔法世界的生活。'
        },
        {
          id: 4,
          gameid: '292030',
          name: '巫师3：狂猎',
          price: '127',
          imgsrc: 'https://media.st.dl.eccdnx.com/steam/apps/292030/header.jpg',
          goodrating: 97,
          recentreviews: '好评如潮',
          releasedate: '2015-05-18',
          developers: 'CD PROJEKT RED',
          description: '你是利维亚的杰洛特，一名职业怪物猎人，受雇于追捕传说中的活武器：血与酒的孩子。'
        },
        {
          id: 5,
          gameid: '578080',
          name: '绝地求生大逃杀',
          price: '98',
          imgsrc: 'https://media.st.dl.eccdnx.com/steam/apps/578080/header.jpg',
          goodrating: 65,
          recentreviews: '褒贬不一',
          releasedate: '2017-12-21',
          developers: 'PUBG Studios',
          description: '绝地求生是一款大逃杀类型的游戏，每局游戏将有一百名玩家参与，游戏结束时只有最后一人或一队获得胜利。'
        },
        {
          id: 6,
          gameid: '752590',
          name: '只狼：影逝二度',
          price: '268',
          imgsrc: 'https://media.st.dl.eccdnx.com/steam/apps/752590/header.jpg',
          goodrating: 94,
          recentreviews: '特别好评',
          releasedate: '2019-03-22',
          developers: 'FromSoftware',
          description: '在战国时代的日本，独臂狼与你的主人一道，面对众多强大敌人，保护一位特殊的年轻皇族后裔。'
        }
      ]
    };
  },
  components: {
    Header: () => import('@/components/Header')
  },
  methods: {
    formatPrice(val) {
      return val === 0 ? '免费' : `¥${val}`;
    },
    formatRating(val) {
      return `${val}%`;
    },
    formatDate(date) {
      if (!date) return '未知';
      if (typeof date === 'string') return date;
      
      const d = new Date(date);
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
    },
    resetFilters() {
      this.recommendGame = {
        price: 200,
        tags: [],
        recentreviews: null,
        numberofreviews: 100,
        goodrating: 60,
        lang: ['简体中文'],
        releasedate: null
      };
    },
    getgameInfo() {
      this.loading = true;
      
      // 模拟API请求
      setTimeout(() => {
        this.loading = false;
        // 实际项目中应该使用以下请求
        /*
        axios
          .post(this.$globle.allurl + "getrecommendgame", this.recommendGame)
          .then(res => {
            this.gameList = res.data;
            this.totalGames = res.data.length;
            
            // 处理游戏列表数据
            for (let i = 0; i < this.gameList.length; i++) {
              this.gameList[i].imgsrc = "https://media.st.dl.eccdnx.com/steam/apps/" + this.gameList[i].gameid + "/header.jpg";
              if (this.gameList[i].price === 0) {
                this.gameList[i].price = "免费开玩";
              }
            }
          })
          .catch(err => {
            this.$message.error('获取游戏数据失败');
            console.error(err);
          })
          .finally(() => {
            this.loading = false;
          });
        */
        
        // 使用假数据
        this.totalGames = this.gameList.length;
      }, 800);
    },
    changeGogame(game) {
      this.$router.push({
        name: "gameDetail",
        query: {
          id: game.id,
        },
      });
    },
    addToWishlist(game) {
      this.$message({
        message: `已将《${game.name}》添加到愿望单`,
        type: 'success'
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      // 实际项目中应该重新加载对应页的数据
    }
  },
  created() {
    // 页面加载时初始化数据
    this.totalGames = this.gameList.length;
  }
};
</script>

<style scoped>
@import url("../../css/mycss.css");

.game-recommend-page {
  min-height: 100vh;
  background-color: #f5f7fa;
}

.content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
}

.page-title {
  text-align: center;
  margin-bottom: 30px;
}

.page-title h1 {
  font-size: 28px;
  color: #303133;
  margin-bottom: 10px;
}

.page-title p {
  font-size: 16px;
  color: #909399;
}

.filter-card {
  border-radius: 8px;
  margin-bottom: 20px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.game-list-card {
  border-radius: 8px;
  min-height: 600px;
}

.loading-container {
  padding: 20px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  color: #909399;
}

.empty-icon {
  font-size: 60px;
  margin-bottom: 20px;
  color: #c0c4cc;
}

.game-list {
  padding: 10px 0;
}

.game-card {
  margin-bottom: 20px;
  transition: transform 0.3s;
  overflow: hidden;
}

.game-card:hover {
  transform: translateY(-5px);
}

.game-card-content {
  display: flex;
  flex-direction: row;
}

.game-image {
  flex: 0 0 230px;
  position: relative;
  overflow: hidden;
}

.game-image img {
  width: 100%;
  height: 107px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s;
}

.game-image img:hover {
  transform: scale(1.05);
}

.game-price {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #f56c6c;
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.game-price.free {
  background-color: #67c23a;
}

.game-info {
  flex: 1;
  padding: 0 15px;
  overflow: hidden;
}

.game-title {
  font-size: 18px;
  margin: 0 0 5px 0;
  cursor: pointer;
  color: #303133;
}

.game-title:hover {
  color: #409EFF;
}

.game-id {
  font-size: 12px;
  color: #909399;
  margin-bottom: 10px;
}

.game-tags {
  margin-bottom: 10px;
}

.game-tags .el-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}

.game-description {
  font-size: 14px;
  color: #606266;
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.game-actions {
  margin-top: 10px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .game-card-content {
    flex-direction: column;
  }
  
  .game-image {
    flex: 0 0 auto;
    margin-bottom: 15px;
  }
  
  .game-image img {
    height: auto;
    max-height: 150px;
  }
  
  .game-info {
    padding: 0;
  }
}
</style>
